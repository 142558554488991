'use client';

import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { CMS } from '@/types/CMS';
import Container from '../Layout/Container';
import CMSButton from './CMSButton';
import { useMobile } from '../../hooks/useMobile';

type IHeroSliderProps = {
    anchor?: string;
    data: CMS.Section.SectionsHeroSliderComponent
};

const HeroSlider = (props: IHeroSliderProps) => {
    const { anchor, data: { slide } } = props;
    const [index, setIndex] = useState<number>(0);
    const isMobile = useMobile();

    return (
        <div id={anchor} className=" md:bg-gradient-blue-hero-one-v bg-gradient-blue-hero-one-h relative">
            <AnimatePresence mode="wait" initial>
                <Container className="relative z-10 p-10 md:py-28 text-center md:text-left">
                    <div className="flex flex-col-reverse md:flex-row justify-between w-full text-white items-center md:space-x-20 ">
                        {slide.map((_slide, i) => {
                            if (i === index) {
                                return (
                                    <motion.div
                                        initial={{ opacity: 0, x: -200 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -200 }}
                                        transition={{ duration: 0.5 }}
                                        key={_slide.id}
                                        className="max-w-[508px] space-y-5 md:w-1/2 w-full min-h-[469px] flex flex-col justify-center">
                                        <div className="space-y-5">
                                            <h1 className="font-heading md:text-2xl text-[48px] leading-[100%]">
                                                {_slide.title}
                                            </h1>
                                            <p className="w-full max-w-[440px] font-secondary font-medium md:text-2sm text-[14px] leading-[160%]">
                                                {_slide.text}
                                            </p>
                                            <CMSButton href={_slide.buttonUrl} light size="xl" className="mx-auto md:mx-0">
                                                {_slide.buttonText}
                                            </CMSButton>
                                        </div>
                                    </motion.div>
                                );
                            }
                            return null;
                        }
                        )}

                        <div className={clsx('flex flex-col md:mb-0  md:w-1/2 w-full justify-center items-center min-h-[469px]', {
                            'mb-24': slide.length > 1
                        })}>
                            {slide.map((_slide, i) => {
                                if (i === index) {
                                    return <motion.img
                                        initial={{ opacity: 0, x: 200 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 200 }}
                                        transition={{ duration: 0.5 }}
                                        className="w-full max-w-[465px] block"
                                        src={isMobile && _slide.mobileImage ? _slide.mobileImage?.data?.attributes.url : _slide.image?.data?.attributes.url ?? ''}
                                        alt={isMobile && _slide.mobileImage ? _slide.mobileImage?.data?.attributes.alternativeText : _slide.image?.data?.attributes.alternativeText}
                                        key={_slide.id}
                                    />;
                                }
                                return null;
                            })}
                            {slide.length > 1 && (
                                <div className="flex flex-row gap-4 mx-auto mt-[3.625rem]">
                                    {slide.map((_slide, i) => {
                                        return <motion.div onClick={() => setIndex(i)} key={_slide.id} className={clsx('w-[0.625rem] h-[0.625rem] cursor-pointer bg-grey9', i === index ? 'bg-grey2 rounded-full' : 'bg-grey9 opacity-20 rounded-full')} />;
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </AnimatePresence>
        </div>
    );
};

export default HeroSlider;
