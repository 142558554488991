'use client';

import React from 'react';
import useAuth from '@/contexts/AuthContext';
import useUnAuth from '@/contexts/UnAuthContext';
import { usePathname } from 'next/navigation';
import Button, { IButtonProps } from './Button';

const CMSButton = (props: IButtonProps) => {
    const { href, ...rest } = props;
    const { user } = useAuth();
    const { showSignUpOrLogin } = useUnAuth();
    const pathname = usePathname();

    if (href === '/signup') {
        if (user) {
            return null;
        }
        return (
            <>
                <div className="md:hidden">
                    <Button href={{ pathname: '/mobile/signup', query: { redirect: pathname } }} {...rest} />
                </div>
                <div className="hidden md:block">
                    <Button
                        onClick={() => {
                            showSignUpOrLogin('signup');
                        }}
                        {...rest} />
                </div>
            </>);
    }
    return <Button href={href} {...rest} />;
};

export default CMSButton;
