'use client';

import React, {} from 'react';
import { CMS } from '@/types/CMS';
import FullWidthImage from './FullWidthImage';
import { Carousel } from '../ecommerce/carousel/carousel';

const responsive = {
    superLargeDesktop: {
        breakpoint: { min: 1440 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 1
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const FullWidthImageSlider = (props: { anchor?: string; data: CMS.Section.SectionsFullWidthImageSliderComponent }) => {
    const { anchor, data } = props;
    return (
        <div id={anchor} className="">
            <div className="mx-auto relative">
                <Carousel
                    showArrows
                    arrowClass={{
                        left: 'left-[1rem] [&_button]:bg-white [&_button]:w-[36px] [&_button]:h-[36px] [&_button]:rounded-full',
                        right: 'right-[1rem] [&_button]:bg-white [&_button]:w-[36px] [&_button]:h-[36px] [&_button]:rounded-full'
                    }}
                    autoPlayDelay={6000}
                    touchMaxOverDrag={0}
                    autoPlay
                    pauseOnHover
                    showDots
                    showDotsOutside={false}
                    dotListClass="pb-1"
                    breakpoints={responsive}>
                    {data.slides.map((item) => <FullWidthImage key={item.id} data={item} />)}
                </Carousel>

            </div>
        </div>
    );
};

export default FullWidthImageSlider;
