'use client';

import React, { useMemo } from 'react';

import Link from 'next/link';
import { CMS } from '@/types/CMS';
import Container from '../Layout/Container';
import Button from './Button';
import { useMobile } from '../../hooks/useMobile';

type IHeroSliderProps = {
    anchor?: string;
    data: CMS.Section.SectionsHeroComponent
};

const Hero = (props: IHeroSliderProps) => {
    const { anchor, data: { SubText, Image, mobileImage = null, Text, Button: button } } = props;
    const isMobile = useMobile();

    const renderedPicture = useMemo(() => {
        if (isMobile && mobileImage) {
            return <img className="w-[100px] h-[100px]" alt={Text} src={mobileImage.data?.attributes.url} />;
        }
        if (Image) {
            return <img className="w-[100px] h-[100px]" alt={Text} src={Image.data?.attributes.url} />;
        }
        return null;
    }, [Image, Text, isMobile, mobileImage]);

    return (
        <Container>
            <div id={anchor} className="flex flex-col justify-center items-center space-y-[50px] py-[80px]">
                {renderedPicture}
                {Text && <h3 className="max-w-[800px] font-heading capitalize text-xl font-[400] leading-[40px] text-center">{Text}</h3>}
                {SubText && <p className="max-w-[800px] text-[14px] font-[400] font-secondary text-center">{SubText}</p>}
                {button && button.url && button.url !== '' && <Link href={button.url}><Button className="mx-auto">{button.text}</Button></Link>}
                {button && !button.url && button.page && button.page.data && button.page.data.attributes && button.page.data.attributes.slug !== '' && <Link href={button.page.data.attributes.slug}><Button className="mx-auto">{button.text}</Button></Link>}
                {button && !button.url && !button.page?.data && button.SectionAnchor && <Button href={`#${button.SectionAnchor}`} className="mx-auto">{button.text}</Button>}
            </div>
        </Container>
    );
};

export default Hero;
