'use client';

import React, { useMemo } from 'react';
import { Item } from '@/api';
import Link from 'next/link';

import Container from '../Layout/Container';
import ProductTile from './ProductTile/ProductTile';

import { Carousel } from './carousel/carousel';

const responsive = {
    superLargeDesktop: {
        breakpoint: { min: 1440 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 3
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const ItemsCarousel = (props: { anchor?: string; title?: string, items: Item[], link?: string }) => {
    const { anchor, items, title, link } = props;

    const renderedTitle = useMemo(() => {
        if (link && title) {
            return <Link href={link} prefetch={false}><h3 id={anchor} className="font-heading text-xl mb-4 md:smb-12 text-center w-full">{title}</h3></Link>;
        }
        if (title) {
            return <h3 id={anchor} className="font-heading text-xl mb-4 md:smb-12 text-center w-full">{title}</h3>;
        }
        return null;
    }, [title, link, anchor]);

    return (
        <Container className="my-10">
            {renderedTitle}
            <div className="mx-auto relative">
                {items.length === 1 ? (
                    <div className="max-w-[326px] mx-auto">
                        <ProductTile data={items[0]} />
                    </div>
                ) :
                    (
                        <Carousel
                            breakpoints={responsive}
                            showDots
                            showArrows
                            autoPlay
                            pauseOnHover
                            arrowClass={{ left: 'w-8', right: 'w-8' }}
                        >
                            {items.map((item) => <ProductTile key={item.id} data={item} />)}
                        </Carousel>
                    )
                }

            </div>
        </Container>
    );
};

export default ItemsCarousel;
