'use client';

import React, {} from 'react';
import { Auction, Item } from '@/api';

import useAuth from '@/contexts/AuthContext';
import ProductTileImage from '@/components/ecommerce/ProductTile/ProductTileImage';
import useAuction from '@/hooks/useAuction';
import BidTimer from '@/components/general/BidTimer';

import i18next from 'i18next';
import Button from '@/components/general/Button';
import Price from '@/components/general/Price';
import Link from 'next/link';
import { Carousel } from './carousel/carousel';

const responsive = {
    superLargeDesktop: {
        breakpoint: { min: 1440 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 1440, min: 1080 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1080, min: 720 },
        items: 1
    },
    smallTablet: {
        breakpoint: { max: 720, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const BannerCarouselItem = ({ item }: { item: Item }) => {
    const { user } = useAuth();

    const { hasAuction, auctionOver, auction, auctionType, comingSoon } = useAuction(item);
    return (
        <div className="flex md:flex-row flex-col justify-between max-w-[1248px] mx-auto items-center md:h-[304px] gap-[40px] md:gap-0 h-full">
            <div className="md:w-[40%] flex flex-col items-center justify-center gap-[20px]">
                <h2 className="font-heading text-[48px] font-[350] tracking-[-2px] leading-[100%] text-center max-w-[320px]">{item.name}</h2>
                {hasAuction && auctionType === Auction.TypeEnum.Auction &&
                    <div className="flex flex-row items-center gap-[8px]">
                        <span className="text-[12px] font-[500] text-grey15">{auction?.amount_of_bids} {i18next.t('common:item-text.current-bid-text.bids')}</span>
                        {!auctionOver && <><span className="text-grey14 text-[12px] font-[500] capitalize">|</span><span className="text-[12px] font-[500] text-grey15 capitalize"><BidTimer dueDate={new Date(auction?.end_date ?? '')} /></span></>}
                    </div>
                }
                {!hasAuction && comingSoon &&
                    <div className="flex flex-row items-center gap-[8px]">
                        <span className="text-[12px] font-[500] text-grey15">{i18next.t('common:product-tile.starts-soon')}</span>
                        {comingSoon?.startTime && <><span className="text-grey14 text-[12px] font-[500] capitalize">|</span><span className="text-[12px] font-[500] text-grey15 capitalize"> <BidTimer dueDate={comingSoon?.startTime} /></span></>}
                    </div>
                }
            </div>
            {item && item.images && item.images.length > 0 && <div className="md:w-[20%] w-[248px] flex flex-row justify-center"><ProductTileImage data={item} user={user} firstImage={item.images[0]} secondImage={undefined} /></div>}
            <div className="w-[40%] flex flex-row justify-center">
                {hasAuction && !auctionOver ?
                    (auctionType === Auction.TypeEnum.Auction ?
                        <div>
                            <Price className="text-[1.375rem] font-[700]" justification="C" number={auction?.current_amount ?? '0'} />
                            <Link href={`/item/${item.slug}`}><Button className="mt-[12px] md:!px-10">{i18next.t('common:button-text.place-bid')}</Button></Link>
                        </div> :
                        <div>
                            <Price className="text-[1.375rem] font-[700]" justification="C" number={auction?.buy_now_amount ?? '0'} />
                            <Link href={`/item/${item.slug}`}><Button className="mt-[12px] md:!px-10">{i18next.t('common:button-text.buy-now')}</Button></Link>
                        </div>
                    ) : <Link href={`/item/${item.slug}`}><Button className="mt-[12px] md:!px-10">{i18next.t('common:button-text.view-item')}</Button></Link>
                }
            </div>
        </div>
    );
};

const BannerCarousel = (props: { anchor?: string; title?: string, items: Item[], background?: string }) => {
    const { anchor, items, background } = props;
    return (
        <div id={anchor} className="p-[48px] m-[32px] rounded-[8px]" style={{ backgroundColor: `#${background}` }}>
            <div className="mx-auto relative">
                <Carousel
                    showArrows
                    arrowClass={{
                        left: 'left-[-2rem] [&_button]:bg-white [&_button]:w-[36px] [&_button]:h-[36px] [&_button]:rounded-full',
                        right: 'right-[-2rem] [&_button]:bg-white [&_button]:w-[36px] [&_button]:h-[36px] [&_button]:rounded-full'
                    }}
                    autoPlayDelay={6000}
                    autoPlay
                    pauseOnHover
                    breakpoints={responsive}
                    dotListClass="pt-[20px]">
                    {items.map((item) => <BannerCarouselItem item={item} key={`CarouselBanner${item.id}`} />)}
                </Carousel>

            </div>
        </div>
    );
};

export default BannerCarousel;
