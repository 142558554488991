'use client';

import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { CMS } from '@/types/CMS';
import Container from '../Layout/Container';
import Button from './Button';

type IThreeBlocksProps = {
    anchor?: string;
    data: CMS.Section.SectionsThreeBlocksComponent
};

const ThreeBlocks = (props: IThreeBlocksProps) => {
    const { anchor, data: { Items, Title, Button: button, Type } } = props;
    if (!Items) {
        return null;
    }
    return (
        <Container className="py-[80px]">
            {Title && <h2 id={anchor} className="text-center font-heading capitalize text-xl mb-[50px]">{Title}</h2>}
            <div className="flex md:flex-row flex-col justify-between   md:space-x-[48px] space-y-[24px] md:space-y-0">
                {Items.map((block) => {
                    return (
                        <div style={{ boxShadow: block.Border ? '0px 0px 5.62501px 0px #B3C0EF' : 'none' }} key={`KeyIdItemTB-${block.id}`} className={clsx('md:w-[33%] w-[100%] text-center flex flex-col items-center justify-center space-y-[24px]', block.Border && 'p-[20px]')}>
                            <Link className="block" href={block.Link || ''}>
                                <img
                                    alt={block.Title}
                                    className={clsx('block object-cover', {
                                        'w-[48px] h-[48px]': Type !== CMS.Section.SectionsThreeBlocksComponent.TypeEnum.TypeImage,
                                        'w-[200px] h-[200px]': Type === CMS.Section.SectionsThreeBlocksComponent.TypeEnum.TypeImage
                                    })}
                                    src={block.Icon.data.attributes.url} />
                            </Link>
                            <Link className="block" href={block.Link || ''}>
                                <h3 className="font-heading capitalize text-[24px] font-[400]">{block.Title}</h3>
                            </Link>
                            <span className="block text-[14px] font-[400] leading-[24px]">{block.Text}</span>
                        </div>
                    );
                })}
            </div>
            {button && button.url && button.url !== '' && <Link href={button.url}><Button className="my-[48px] mx-auto">{button.text}</Button></Link>}
            {button && !button.url && button.page && button.page.data && button.page.data.attributes && button.page.data.attributes.slug !== '' && <Link href={button.page.data.attributes.slug}><Button className="my-[48px] mx-auto">{button.text}</Button></Link>}
        </Container>
    );
};

export default ThreeBlocks;
