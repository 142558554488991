'use client';

import clsx from 'clsx';
import React, { useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { CMS } from '@/types/CMS';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import { useMobile } from '../../hooks/useMobile';
import Button from './Button';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export type IBannerProps = {
    anchor?: string;
    data: CMS.Section.SectionsBannerComponent
};

const Banner = (props: IBannerProps) => {
    const {
        anchor,
        data: {
            direction = CMS.Section.SectionsBannerComponent.DirectionEnum.Ltr,
            picture,
            mobilePicture = null,
            background,
            title,
            text,
            light = false,
            smallImage = false,
            descriptionImage = null,
            textTwo,
            button,
            video,
            backgroundImage,
            backgroundImageSizing,
            maxHeight
        }
    } = props;

    const isMobile = useMobile();

    const [videoOpen, setVideoOpen] = useState(false);

    return (
        <div id={`${anchor}`} className={clsx(background, 'relative')}>
            {backgroundImage && backgroundImage.data && <img className={`absolute z-0 h-full w-full inset-0 ${backgroundImageSizing === 'Fill' ? 'object-fill' : backgroundImageSizing === 'Fit' ? 'object-cover' : ''}`} src={backgroundImage?.data?.attributes?.url ?? ''} alt="" />}
            <div
                className={clsx(
                    'flex md:flex-row flex-col-reverse relative md:min-h-[448px] md-tablet:min-h-[300px] h-full max-w-[1600px] mx-auto px-8 md:px-0',
                    direction === CMS.Section.SectionsBannerComponent.DirectionEnum.Ltr ? 'flex-row' : 'md:flex-row-reverse',
                    // smallImage && background
                )}
                style={maxHeight && isMobile && maxHeight > 0 ? { maxHeight } : {}}
            >
                <div
                    className={clsx(
                        'md:w-1/2 w-full flex justify-center items-center md:py-[128px] pb-4 md:pb-[64px] z-[1]',
                        // !smallImage && background
                    )}
                >
                    <div
                        className={clsx(
                            'md:max-w-[70%] p-10 md:p-0 text-center md:text-left tablet:py-[20px]',
                            light ? 'text-main' : 'text-white'
                        )}
                    >
                        <h3 className={clsx('font-heading font-[400] md:text-5xl text-3xl mb-4 sm-tablet:px-[20px]')}>
                            {title}
                        </h3>
                        {text && <span className="text-[14px] md:text-[1rem] sm-tablet:px-[20px] block rich-text"><div dangerouslySetInnerHTML={{ __html: text }} /></span>}
                        {descriptionImage && <img
                            className="w-full object-cover my-[24px]"
                            src={descriptionImage?.data?.attributes.url ?? undefined}
                            alt={descriptionImage?.data?.attributes.alternativeText} />}
                        {textTwo && <span className="text-[14px] md:text-[1rem] sm-tablet:px-[20px] block">{textTwo}</span>}
                        {button && button.url && button.url !== '' && (
                            <Link href={button.url}>
                                <Button className="my-[24px] mr-auto" light={button.type === 'secondary'}>{button.text}</Button>
                            </Link>
                        )}
                        {button && !button.url && button.page && button.page.data && button.page.data.attributes && button.page.data.attributes.slug !== '' && (
                            <Link href={button.page.data.attributes.slug}>
                                <Button className="my-[24px] mr-auto" light={button.type === 'secondary'}>{button.text}</Button>
                            </Link>
                        )}
                        {button && !button.url && !button.page?.data && button.SectionAnchor && (
                            <Link href={`#${button.SectionAnchor}`}>
                                <Button className="my-[24px] mr-auto" light={button.type === 'secondary'}>{button.text}</Button>
                            </Link>
                        )}
                    </div>
                </div>
                {video && video.data ?
                    <div className="md:w-1/2 w-auto md:min-h-[448px] min-h-[unset] object-cover z-[1]">
                        <div className="w-full h-full cursor-pointer" onClick={() => setVideoOpen(true)}>
                            <ReactPlayer width="100%" height="100%" playsinline muted controls url={video.data?.attributes.url ?? ''} />
                        </div>
                        <Lightbox
                            index={0}
                            plugins={[Video]}
                            slides={[{ type: 'video', sources: [{ src: video.data?.attributes.url ?? '', type: 'video/mp4' }], autoPlay: true, preload: 'auto', loop: true }]}
                            open={videoOpen}
                            close={() => setVideoOpen(false)}
                        />
                    </div>
                    : smallImage ? (
                        <div className="md:w-1/2 w-full flex justify-center items-center py-[64px] md:py-[0] z-[1]">
                            <img
                                className="w-full max-w-[380px] object-cover"
                                src={isMobile && mobilePicture ? mobilePicture?.data?.attributes.url : picture?.data?.attributes.url ?? undefined}
                                alt={isMobile && mobilePicture ? mobilePicture?.data?.attributes.alternativeText : picture?.data?.attributes.alternativeText} />
                        </div>
                    ) : (
                        <img
                            className="md:w-1/2 w-auto md:min-h-[448px] min-h-[unset] object-contain z-[1]"
                            src={isMobile && mobilePicture ? mobilePicture?.data?.attributes.url : picture?.data?.attributes.url ?? undefined}
                            alt={isMobile && mobilePicture ? mobilePicture?.data?.attributes.alternativeText : picture?.data?.attributes.alternativeText} />
                    )}
            </div>
        </div>
    );
};

export default Banner;
