'use client';

import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { motion } from 'framer-motion';
import { CMS } from '@/types/CMS';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

type IVideoHeroSectionProps = {
    anchor?: string;
    data: CMS.Section.SectionsVideoHeroComponent;
};

const VideoHero = (props: IVideoHeroSectionProps) => {
    const { anchor, data: { video, VideoUrlGif } } = props;
    const [gifUrl, setGifUrl] = useState<string | null>(null);
    const [readyToPlay, setReadyToPlay] = useState<boolean>(false);

    const paddingTop = (video.data?.attributes.width && video.data?.attributes.height) ? video.data?.attributes.height / (video.data?.attributes.width / 100) : 56.25;

    return (
        <div id={anchor} className="bg-black">
            <motion.div animate={{ opacity: readyToPlay ? 1 : 0 }} className="relative" style={{ paddingTop: `${paddingTop}%` }}>
                {gifUrl ? <img onLoad={() => setReadyToPlay(true)} src={gifUrl} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%' }} alt="Hero" /> : <ReactPlayer onReady={() => setReadyToPlay(true)} onError={() => { setGifUrl(VideoUrlGif); }} style={{ position: 'absolute', top: 0, left: 0 }} width="100%" height="100%" config={{}} playsinline playing muted loop controls={false} url={video.data?.attributes.url} />}
            </motion.div>
        </div>
    );
};

export default VideoHero;
