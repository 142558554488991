'use client';

import { useEffect } from 'react';

export function ServerClientCommunication(props: { values: { [key: string]: string } }) {
    const { values } = props;

    useEffect(() => {
        try {
            Object.keys(values).forEach((key) => {
                localStorage.setItem(key, values[key]);
            });
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
    }, [values]);

    return null;
}
