'use client';

import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CMS } from '@/types/CMS';
import i18next from 'i18next';
import Container from '../Layout/Container';

type IFaqProps = {
    anchor?: string;
    data: CMS.Section.SectionsFaqComponent;
};

const Faq = (props: IFaqProps) => {
    const { anchor, data: { questions } } = props;
    const [selectedQuestion, setSelectedQuestion] = useState<number>(-1);
    if (!questions) {
        return null;
    }
    return (
        <div id={anchor} className="bg-gradient-blue text-white py-24">
            <Container>
                <h3 className="font-heading capitalize text-xl mb-12 text-center">{i18next.t('common:general.faq')}</h3>
                <AnimatePresence mode="wait" initial={false}>
                    <div className="space-y-[20px]">
                        {questions.map((question) =>
                            <div
                                key={question.id}
                                className="bg-black bg-opacity-20 rounded-xl p-8 transitionable cursor-pointer hover:scale-[1.01] noTransition"
                                onClick={() => {
                                    if (question.id === selectedQuestion) {
                                        setSelectedQuestion(-1);
                                    } else {
                                        setSelectedQuestion(question.id);
                                    }
                                }}>
                                <button className="font-heading capitalize text-[28px]">{question.question}</button>
                                {question.id === selectedQuestion &&
                                    <motion.div
                                        initial={{ height: 0 }}
                                        animate={{ height: 'auto' }}
                                        exit={{ height: 0 }}
                                        className="overflow-hidden space-y-[10px] h-0 "
                                        key={`Q-${question}`}>

                                        <span>{question.answer}</span>
                                    </motion.div>}
                            </div>
                        )}
                    </div>
                </AnimatePresence>
            </Container>
        </div>
    );
};

export default Faq;
